import React from "react"

import SEO from "../components/seo"

const AboutPage = () => (
  <>
    <SEO title="About" />
    <div className="letter-content">
      <p>Void!</p>
      <p>We’ve so many exciting things to tell you - we barely know where to begin! We suppose the most important thing to tell you about is a new project of ours - it’s called “Love Letters to the Void”. People, all sorts of people, write letters to you Void, and letters to other entities that don’t typically receive correspondence — like an asteroid mining robot, or the last amoeba, or the first sentient plant. We think it’s going to be great, and we hope you do to. Now what were those other exciting things...? You know we’ve clean forgotten. We suppose those can wait for our next letter.</p>
      <p>Until next time—</p>
      <p>Serendipity and Impulse</p>
    </div>
  </>
)

export default AboutPage
